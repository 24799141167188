body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: Montserrat, "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* AVRT BRANDING */

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  src: local("Montserrat"),
    url(./fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  src: local("Montserrat"),
    url(./fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  src: local("Montserrat"),
    url(./fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  src: local("Montserrat"),
    url(./fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
}

.PatientRecord-name {
  font-weight: 700;
}
h1 {
  font-weight: 800;
  color: #3a4588;
}
h2 {
  font-weight: 700;
  color: #3a4588;
}
h3 {
  font-weight: 600;
  color: #3a4588;
}
h4 {
  font-weight: 500;
  color: #3a4588;
}

a:link {
  color: #00679b;
}

a:visited {
  color: #00679b;
}

a:hover {
  color: #3a4588;
}

a:active {
  color: #00679b;
}
